@keyframes preload {
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}
.preloader {
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader > div {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 18px;
}
.preloader > div > div {
  width: 18px;
  height: 18px;
  background: white;
  opacity: 0.3;
  transform: translateY(10px);
  border-radius: 100%;
  animation: preload 1s infinite;
}
.preloader > div > div:nth-child(1) {
  animation-delay: -200ms;
}
.preloader > div > div:nth-child(2) {
  animation-delay: -100ms;
}

.home {
  padding: 32px;
}

.home header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.search-container {
  position: relative;
}

.search-container input {
  font: inherit;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  padding: 8px 10px;
  width: 350px;
  outline: none;
}

.search-container input:focus {
  background: rgba(255, 255, 255, 0.15);
}

.search-container ul {
  position: absolute;
  top: 40px;
  left: 0;
  list-style: none;
  padding: 0;
  z-index: 999;
  margin: 0;
  background: rgb(31, 29, 37);
  width: 100%;
}

.search-container ul li {
  padding: 10px 10px;
  cursor: pointer;
  line-height: 1.15;
  display: flex;
  gap: 8px;
}
.search-container ul li:nth-child(2n) {
  background: rgba(255, 255, 255, 0.02);
}

.search-container ul li img {
  height: 36px;
  width: 36px;
}

.search-container ul li div > div {
  font-weight: 500;
}
.search-container ul li span {
  font-size: 0.9em;
  opacity: 0.5;
}

.search-container ul li.selected {
  background: rgba(255, 255, 255, 0.1);
}

.champions-index {
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  gap: 18px;
  justify-content: space-between;
  margin-bottom: 24px;
}

.champions-index > a {
  display: block;
  text-decoration: none;
  position: relative;
}

.champions-index > a::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  opacity: 0;
  transition: opacity 150ms;
  z-index: -1;
}

.champions-index > a:hover::before {
  opacity: 1;
}

.champions-index > a > img {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  display: block;
}

.champions-index > a > div {
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 4px;
  opacity: 0.7;
  transition: opacity 300ms;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  text-align: center;
}

.champions-index > a:hover > div {
  opacity: 1;
}

.skinlines-index {
  columns: 8;
}

@media screen and (max-width: 1750px) {
  .skinlines-index {
    columns: 7;
  }
}

@media screen and (max-width: 1500px) {
  .skinlines-index {
    columns: 6;
  }
}

@media screen and (max-width: 1250px) {
  .skinlines-index {
    columns: 5;
  }
}

@media screen and (max-width: 1000px) {
  .skinlines-index {
    columns: 4;
  }
}

@media screen and (max-width: 750px) {
  .skinlines-index {
    columns: 3;
  }
}

.skinlines-index > a {
  display: block;
  text-decoration: none;
  line-height: 1.2;
  padding: 8px 6px;
  margin: 2px 0;
  opacity: 0.7;
  transition: background-color 150ms, opacity 300ms;
}

.skinlines-index > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.skin-list {
  padding: 32px;
}

.skin-list header {
  margin-bottom: 32px;
}

.skin-list header a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
  transition: opacity 150ms;
  position: relative;
}

.skin-list > header a:hover {
  opacity: 1;
}

.skin-list > header a::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -6px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 12px);
  opacity: 0;
  transition: opacity 150ms;
  z-index: -1;
}

.skin-list > header a:hover::before {
  opacity: 1;
}

.skin-list > header a span {
  display: block;
  margin-left: 8px;
  font-size: 1.1em;
  font-weight: 500;
}

.skin-list > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 20px 32px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 24px;
}

.skin-list > div > a {
  display: block;
  text-decoration: none;
  position: relative;
}

.skin-list > div > a::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -12px;
  left: -12px;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  opacity: 0;
  transition: opacity 150ms;
  z-index: -1;
}

.skin-list > div > a:hover::before {
  opacity: 1;
}

.skin-list > div > a > img {
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  display: block;
}

.skin-list > div > a > div {
  margin-top: 8px;
  opacity: 0.7;
  font-weight: 600;
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.2;
}

.skin-list > div > a > div > img {
  height: 20px;
  margin-right: 8px;
}

.skin-list > div > a:hover > div {
  opacity: 1;
}

.skin-carousel header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  z-index: 999;
  display: flex;
  gap: 24px;
  align-items: center;
}

.skin-carousel header a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
  position: relative;
  transition: opacity 150ms;
}

.skin-carousel > header a:hover {
  opacity: 1;
}

.skin-carousel > header a::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -6px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 12px);
  opacity: 0;
  transition: opacity 150ms;
  z-index: -1;
}

.skin-carousel > header a:hover::before {
  opacity: 1;
}

.skin-carousel > header a span {
  display: block;
  margin-left: 8px;
  font-size: 1.1em;
  font-weight: 500;
}

.skin-carousel .current > * {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  transition: left 300ms, width 300ms, opacity 300ms;
}

.skin-carousel .current.fill > * {
  object-fit: cover;
}

.skin-carousel .current > *.loading {
  opacity: 0;
  transition: none;
}

.skin-carousel .current.hover-prev > *,
.skin-carousel .current.hover-next > * {
  transition-delay: 100ms;
  width: calc(100vw - 250px);
  opacity: 0.4;
}

.skin-carousel .current.hover-prev > * {
  left: 250px;
}

.skin-carousel .prev,
.skin-carousel .next {
  position: fixed;
  width: 250px;
  height: 100vw;
  top: 0;
  z-index: 2;
}

.skin-carousel .next {
  left: unset;
  right: 0;
}

.skin-carousel .prev img,
.skin-carousel .next img {
  height: 100vh;
  width: 0;
  transition: width 300ms;
  object-position: center;
  object-fit: cover;
}

.skin-carousel .next img {
  float: right;
}

.skin-carousel .prev div,
.skin-carousel .next div {
  position: fixed;
  z-index: 3;
  font-weight: 500;
  left: 64px;
  bottom: 24px;
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 300ms, opacity 300ms;
}

.skin-carousel .next div {
  left: unset;
  right: 64px;
  transform: translateX(20px);
}

.skin-carousel .prev svg,
.skin-carousel .next svg {
  position: fixed;
  bottom: 24px;
  left: 24px;
  opacity: 0.3;
}

.skin-carousel .next svg {
  left: unset;
  right: 24px;
}

.skin-carousel .prev:hover img,
.skin-carousel .next:hover img {
  transition-delay: 100ms;
  width: 250px;
}

.skin-carousel .prev:hover div,
.skin-carousel .next:hover div {
  transition-delay: 100ms;
  opacity: 1;
  transform: none;
}

.skin-carousel .mouse-event-block {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 200px;
  left: 0;
  z-index: 99;
}

.skin-carousel .overlay {
  position: fixed;
  top: 24px;
  right: 24px;
  height: 24px;
  cursor: default;
  z-index: 100;
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: opacity 150ms;
}

.skin-carousel .overlay:hover {
  opacity: 1;
}

.skin-carousel .overlay > span {
  margin-right: 8px;
  font-weight: 500;
  font-size: 1.1em;
}

.skin-carousel .overlay::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -10px;
  width: calc(100% + 16px);
  height: calc(100% + 12px);
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 250ms;
  z-index: -1;
}
.skin-carousel .overlay:hover::before {
  opacity: 1;
}

.skin-carousel .overlay > div {
  width: calc(100% + 16px);
  min-width: 320px;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  padding: 12px;
  position: absolute;
  top: 30px;
  right: -6px;
  z-index: 100;
  pointer-events: none;
  font-size: 0.9em;
  opacity: 0;
  transition: opacity 250ms;
}

.skin-carousel .overlay:hover > div {
  opacity: 1;
  pointer-events: all;
}

.skin-carousel .overlay > div > h2 {
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 4px;
  font-size: 20px;
}

.skin-carousel .overlay .header {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  line-height: 1.1;
}

.skin-carousel .overlay .header > * {
  position: relative;
  margin-bottom: 8px;
}

.skin-carousel .overlay .header > a[href] {
  text-decoration: none;
}

.skin-carousel .overlay .header > a[href]:hover {
  text-decoration: underline;
}

.skin-carousel .overlay .header > *:not(:last-child)::after {
  content: "\00B7";
  pointer-events: none;
  position: absolute;
  right: -8px;
}

.skin-carousel .overlay > div .rarity {
  display: flex;
  align-items: center;
}
.skin-carousel .overlay > div .rarity img {
  height: 20px;
  margin-right: 8px;
  transform: translateY(-2px);
}

.skin-carousel .overlay > div > p {
  line-height: 1.4;
  margin: 0;
  opacity: 0.9;
}

.skin-carousel .overlay > div > a {
  display: block;
  text-decoration: none;
  margin-top: 6px;
  text-decoration: none;
}
.skin-carousel .overlay > div > a:hover {
  text-decoration: underline;
}

.skin-carousel .btn {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 150ms;
  height: 24px;
}

.skin-carousel .btn:hover {
  opacity: 1;
}

.footer-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

footer {
  padding: 0 32px 32px;
  font-size: 0.8em;
  opacity: 0.5;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 128px;
  align-items: flex-end;
}

footer :nth-child(2) {
  text-align: right;
}
